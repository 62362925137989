import React from "react";
import {
  SectionWrapper,
  Registration,
  PageSectionSimple,
  PageSectionColumns,
  getComponentMap,
  HubspotWebform,
  PageSectionComponentList,
  HeroNext,
} from "./sections";
import { EmbedSection } from "./sections/embed-section";
import { Tabbed } from "./sections/tabbed";
import { CarouselSlides, VIDEO_CARD_DELAY } from "./sections/carousel-slides";
import { ReferenceList } from "./sections/reference-list";
import { ComparisonTable } from "./sections/compare-table";
import { CarouselCardSwipe } from "./sections/carousel-cardswipe";
import { DARK_BACKGROUNDS } from "./global/layout";

const available = getComponentMap();

export const Page = ({ title, sections, slug, isHomepage, backgroundColor }) => (
  <article>
    {sections
      ? sections.map((section, i) => {
        const obj = {
          ...section,
          isDarkBg: DARK_BACKGROUNDS.includes(section.background) ||
            (DARK_BACKGROUNDS.includes(backgroundColor) && !section.background)
        };

        if (!section) {
          return (
            <SectionWrapper {...section} key={i} index={i}>
              Missing Component
            </SectionWrapper>
          );
        }
        switch (section._type) {
          case "pageSectionSimple":
            return (
              <SectionWrapper {...section} key={i} index={i}>
                <PageSectionSimple section={section} />
              </SectionWrapper>
            );

          case "pageSectionColumns":
            return (
              <SectionWrapper {...section} key={i} index={i}>
                {section.style === "tabbed" ? (
                  <Tabbed section={section} />
                ) : (
                  <PageSectionColumns section={section} />
                )}
              </SectionWrapper>
            );

          case "pageSectionComponentList":
            return (
              <SectionWrapper
                {...{ ...obj, noHeaders: true }}
                key={i}
                index={i}
              >
                {section.layout === "tabbed" ? (
                  <Tabbed section={section} />
                ) : (
                  <PageSectionComponentList {...obj} />
                )}
              </SectionWrapper>
            );

          case "embedSection":
            return (
              <SectionWrapper {...section} key={i} index={i}>
                <EmbedSection
                  section={{ ...section }}
                  doc={{ _type: "page", title, slug }}
                />
              </SectionWrapper>
            );

          case "pageComponent":
            if (available.has(section.name)) {
              const sectionObj = available.get(section.name);
              const Tag = sectionObj.component;

              return (
                <SectionWrapper
                  {...{
                    ...obj,
                    noHeaders: sectionObj.noHeaders,
                    desktopOnly: sectionObj.desktopOnly,
                  }}
                  key={i}
                  index={i}
                >
                  <Tag section={obj} />
                </SectionWrapper>
              );
            }
            return (
              <SectionWrapper {...section} key={i}>
                <p>Missing Component: {section.name}</p>
              </SectionWrapper>
            );

          case "webForm":
            return (
              <SectionWrapper {...section} key={i} index={i}>
                <Registration
                  section={{ ...section }}
                  doc={{ _type: "page", title, slug }}
                  supressMessage={true}
                />
              </SectionWrapper>
            );

          case "webFormHubspot":
            return (
              <SectionWrapper {...section} key={i} index={i}>
                <HubspotWebform
                  section={{ ...section }}
                  doc={{ _type: "page", title, slug }}
                  className="mb-36"
                />
              </SectionWrapper>
            );

          case "pageSectionSlides":
            return (
              <SectionWrapper
                {...section}
                key={i}
                index={i}
                noHeaders={i === 0 ? true : null}
              >
                {i === 0 && isHomepage && <HeroNext section={section} />}
                {["carouselCards", "carouselCardsHero"].includes(
                  section.layout
                ) ? (
                  <CarouselCardSwipe
                    section={section}
                    docs={section.content}
                    index={i}
                    // itemsPerSlide={2.1}
                    speed={i !== 0 && section.layout === "carouselCards" ? VIDEO_CARD_DELAY : null}
                    skipSummary={
                      i === 0 || section.layout === "carouselCardsHero"
                    }
                    showSparkles={i === 0 && section.layout === "carouselCardsHero"}
                  />
                ) : section.layout === "verticalTabs" ? (
                  <CarouselSlides section={section} />
                ) : (
                  <CarouselSlides section={section} />
                )}
              </SectionWrapper>
            );

          case "pageSectionList":
            return (
              <SectionWrapper {...section} key={i} index={i}>
                <ReferenceList section={{ ...section }} />
              </SectionWrapper>
            );

          case "pageSectionCompare":
            return (
              <SectionWrapper {...section} key={i} index={i} noHeaders={true}>
                <ComparisonTable section={section} />
              </SectionWrapper>
            );

          default:
            return JSON.stringify(section);
        }
      })
      : null}
  </article>
);
