import React from "react";
import { CardContext } from "../card/context";
import { Card } from "../card/card";
import Masonry from "react-masonry-css";
import { Button } from "../button";
import { ArrowRight } from "../icon/arrow-left";
import { typesMap } from "./resources-list";

import * as styles from "../global/global.module.css";

export const ReferenceList = ({ section }) => (
  <>
    <div
      className="relative"
      style={
        section.layout === "fadeOut"
          ? { maxHeight: "40rem", overflow: "hidden" }
          : null
      }
    >
      {section.layout === "masonry" ? (
        <Masonry
          breakpointCols={{
            default: 3,
            1020: 2,
            600: 1,
          }}
          className={styles.masonryGrid}
          columnClassName={styles.masonryGridColumn}
        >
          {section.list.map((doc, i) => (
            <CardContext.Provider
              key={i}
              value={{
                ...doc,
                title: ["quotable", "story"].includes(doc._type)
                  ? false
                  : doc.title,
                quote: doc._type === "quotable" ? doc : doc.quote,
                hideImage: true,
                // makeThumbnail: !!doc._type === "quotable",
                showSummary: true,
                showByline: false,
                maxColumns: 3,
                // showExtended: true,
                // showSubTags: true,
                hideLabels: doc._type === "quotable",
                hideType: true,
                hideDate: true,
                cta:
                  doc._type === "quotable"
                    ? null
                    : typesMap.get(doc._type)
                      ? typesMap.get(doc._type).cta
                      : typesMap.get("story"),
                showLogo: true,
                isTall: true,
                isMasonry: true,
                skipLink: doc._type === "quotable",
              }}
            >
              <Card />
            </CardContext.Provider>
          ))}
        </Masonry>
      ) : (
        <div className="grid lg:grid-cols-2 xl:grid-cols-3 gap-4">
          {section.list.map((doc, i) => (
            <CardContext.Provider
              key={i}
              value={{
                ...doc,
                title: ["quotable", "story"].includes(doc._type)
                  ? false
                  : doc.title,
                quote: doc._type === "quotable" ? doc : doc.quote,
                hideImage: true,
                makeThumbnail: !!doc._type === "quotable",
                showSummary: true,
                showByline: false,
                maxColumns: 3,
                hideLabels: doc._type === "quotable",
                hideType: true,
                hideDate: true,
                cta:
                  doc._type === "quotable"
                    ? null
                    : typesMap.get(doc._type)
                      ? typesMap.get(doc._type).cta
                      : typesMap.get("story"),
                showLogo: true,
                isTall: true,
                skipLink: doc._type === "quotable",
              }}
            >
              <Card />
            </CardContext.Provider>
          ))}
        </div>
      )}
      {section.layout === "fadeOut" && (
        <div className="absolute right-0 left-0 bottom-0 h-24 bg-gradient-to-t from-green-ultralight to-transparent"></div>
      )}
    </div>
    {section.cta && (
      <div className="text-center mt-12">
        <Button
          href={section.ctaPath || "/resources"}
          text={section.cta}
          bgColor="bg-black"
          icon={<ArrowRight size="12" />}
          iconRight={true}
          className="min-w-64"
        />
      </div>
    )}
  </>
);
