import React, { useContext, useLayoutEffect, useRef, useState } from "react";
import { pageDocPath } from "../../../helpers";
import { ArrowLeft } from "../icon/arrow-left";
import PortableText from "../portableText";
import { cn } from "../../lib/helpers";
import { faArrowsSplitUpAndLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getImgUrl, ImageBuilder } from "../global/image-builder";
import { useInView } from "react-intersection-observer";
import { SLIDE_SPEED } from "./tabbed";
import { SectionContext } from "./context";
import { Button } from "../button";
import { BeforeAfter } from 'react-simple-before-after'

import * as styles from "../portableText.module.css";
import * as globalStyles from "../global/global.module.css";
import tailwindConfig from "../../../tailwind.config";

/**
 * Auto-advance tabbed slide deck with navigation.
 *
 * @todo merge with tabbed.js
 *
 * @todo avoid duplicate code in: carousel-slides.js tabbed.js card-carousel.js carousel-cardswipe.js
 */

export const VIDEO_CARD_DELAY = 5000;

export const CarouselSlides = ({ section }) => {
  const sectionContext = useContext(SectionContext);
  const allRows = section?.slides || section?.content;
  const slides = allRows.filter((r) => r.header);
  const hasHeaderRows = !allRows[0]?._rawContent && !allRows[0]?.mainImage && !allRows[0]?.mainVideo;
  const [active, setActive] = useState(hasHeaderRows ? 1 : 0);
  const [isAutoplayed, setIsAutoplayed] = useState(false);
  const autoPlayRef = useRef();
  const [ref, inView] = useInView({ triggerOnce: true, initialInView: false });
  const [hasAutoplay, setHasAutoplay] = useState(section.effect !== "none" && section.layout !== "verticalTabs");

  const vertical = section.layout === "verticalTabs";

  useLayoutEffect(hasAutoplay ? () => {
    if (inView && !isAutoplayed) {
      setIsAutoplayed(true);
    }

    if (isAutoplayed) {
      autoPlayRef.current = setInterval(() => {
        setActive(active < slides.length - 1 ? active + 1 : 0);
      }, SLIDE_SPEED);
    }

    return () => clearInterval(autoPlayRef.current);
  } : () => { }, [isAutoplayed, inView, active]);

  return (
    <>
      {slides?.length > 0 && (
        <>
          <div ref={ref}></div>
          <div
            className={
              vertical
                ? "flex flex-col md:flex-row gap-6 lg:gap-10"
                : null
            }
          >
            <ul
              className={cn(
                "flex flex-wrap mb-12 md:min-w-64 lg:min-w-88",
                vertical ? "flex-col" : null,
              )}
            >
              {allRows.map((s, i) =>
                !s._rawContent && !s.mainImage && !s.mainVideo ? (
                  <li
                    key={i}
                    className="inline-block p-2 md:p-2.5 pt-3 md:pt-5 mt-1 md:mt-3 text-link leading-tight mr-3 md:mr-0 font-medium border-t border-light-10"
                  >
                    {s.header}
                  </li>
                ) : (
                  <li key={i}>
                    <a
                      role="button"
                      className={cn(
                        "group relative cursor-pointer inline-block p-2 md:p-2.5 mb-0.5 leading-tight transition-nav rounded-md",
                        slides.findIndex((n) => n._key === s._key) === active
                          ? sectionContext.isDarkBg
                            ? "text-white bg-light-5"
                            : "text-black bg-dark-5"
                          : sectionContext.isDarkBg
                            ? "text-light-60 border-light-60"
                            : "text-secondary border-dark-10 hover:border-link",
                        vertical ? "flex justify-between pr-10 md:pr-10" : "pr-3 md:pl-3 bg-dark-5 mr-2",
                        sectionContext.isDarkBg
                          ? "hover:bg-light-5"
                          : "hover:bg-dark-5 hover:text-black font-medium"
                      )}
                      // note: hover trigger when not autoplaying
                      onClick={(e) => {
                        setActive(slides.findIndex((n) => n._key === s._key));
                        setIsAutoplayed(false);
                        setHasAutoplay(false);
                        e.preventDefault();
                      }}
                    >
                      {s.header}
                      {vertical &&
                        slides.findIndex((n) => n._key === s._key) ===
                        active && hasAutoplay && isAutoplayed && (
                          <span
                            className={cn(
                              "absolute right-3 top-2.5 transition-opacity duration-300",
                              slides.findIndex((n) => n._key === s._key) ===
                                active
                                ? "opacity-100"
                                : "opacity-0"
                            )}
                          >
                            <img src="/static/img/icons/spinner.gif" className="w-4 h-4 mt-0.5 rounded-full bg-white" />
                          </span>
                        )}
                    </a>
                  </li>
                )
              )}
              {!vertical && hasAutoplay && (
                <li className="hidden sm:flex ml-4 pb-1 flex-grow justify-end">
                  <a
                    onClick={() => {
                      setIsAutoplayed(!isAutoplayed);
                    }}
                    className={cn(
                      "group relative",
                      globalStyles.carouselButton
                    )}
                    role="button"
                    aria-label="autoplay"
                  >
                    <div className="opacity-0 group-hover:opacity-100 transition-opacity duration-300 absolute top-0 right-0 bottom-0 left-0 bg-dark-10 rounded-lg"></div>
                    <img src="/static/img/icons/spinner.gif" className="w-4 h-4 rounded-full bg-white" />
                  </a>
                </li>
              )}
            </ul>
            <div
              className={cn(
                "gap-12 mb-12",
                vertical
                  ? "flex items-end flex-col gap-6"
                  : slides[active]?._rawContent
                    ? "grid md:grid-cols-1/3-2/3"
                    : null
              )}
            >
              {!vertical && slides[active]?._rawContent && (
                <PortableText
                  blocks={slides[active]?._rawContent}
                  className={styles.checkList}
                />
              )}
              <div className="relative rounded-xl overflow-hidden">
                {slides[active]?.mainVideo && (
                  <React.Fragment key={slides[active]?.mainVideo?.asset.url}>
                    <Video video={slides[active]?.mainVideo} />
                  </React.Fragment>
                )}
                {slides[active]?.mainImageFade ? (
                  <BeforeAfter
                    className={globalStyles.grabbable}
                    afterImage={getImgUrl({ image: slides[active]?.mainImageFade })}
                    beforeImage={getImgUrl({ image: slides[active]?.mainImage })}
                    beforeClassName={globalStyles.beforeAfterImgWrapper}
                    afterClassName={globalStyles.beforeAfterImgWrapper}
                    buttonClassName="flex justify-center items-center shadow-lg border border-dark-20"
                    buttonStyle={{
                      backgroundColor: "white",
                      borderWidth: "2px",
                      minWidth: "3rem",
                      minHeight: "3rem",
                      boxSizing: "content-box",
                      backgroundClip: "content-box"
                    }}
                    beforeStyle={{
                      borderColor: "rgba(0, 0, 0, 0.2)",
                    }}
                  // onChange={() => setHasAutoplay(false)}
                  />

                ) : (
                  <a href={slides[active]?.link}>
                    <ImageBuilder
                      image={slides[active]?.mainImage}
                      alt={slides[active]?.header}
                      className="rounded-2xl"
                    />
                  </a>
                )}
                {vertical && slides[active]?._rawContent && (
                  <PortableText
                    blocks={slides[active]?._rawContent}
                    className={cn("mt-6 text-auxiliary", styles.checkList)}
                  />
                )}
              </div>
            </div>
          </div>
        </>
      )}
      {slides[active]?.contentRefs?.length > 0 ? (
        <Playbooks {...slides[active]} />
      ) : section.contentRefs?.length > 0 ? (
        <Playbooks {...section} />
      ) : null}
    </>
  );
};

const Playbooks = (props) => (
  <>
    {props.header && (
      <h4 className="font-semibold text-lg mb-6">{props.header} playbooks</h4>
    )}
    <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-6 pb-2">
      {props.contentRefs
        ?.filter((p) => p.slug?.current)
        .map((p, i) => (
          <PlaybookTile key={i} doc={p} />
        ))}
    </div>
  </>
);

const PlaybookTile = ({ doc }) => (
  <a
    className="relative flex bg-white text-secondary transform transition-card scale-100 hover:scale-105 p-4 pl-3 rounded-lg border border-dark-10 font-medium pb-12"
    href={pageDocPath(doc)}
  >
    <FontAwesomeIcon
      icon={faArrowsSplitUpAndLeft}
      className="text-dark-70 mt-0.5 mr-1.5"
    />
    <div className="leading-tight">
      {doc.title}
      <p className="text-link text-xs absolute bottom-4">
        See playbook <ArrowLeft rotate={180} />
      </p>
    </div>
  </a>
);

const Video = ({ video, className }) =>
  video ? (
    <video
      controls={true}
      preload="auto"
      playsInline={true}
      // width={width}
      // poster={imageBuilder(image)
      //   .width(width * 2)
      //   .quality(99)
      //   .auto("format")
      //   .url()}
      className={cn("rounded-xl min-h-48", className)}
    >
      <source src={video.asset.url} type="video/mp4" />
    </video>
  ) : null;


const ToggleView = ({ imageOne, imageTwo, setIsAutoplayed, setHasAutoplay, className }) => {
  const [isToggled, setIsToggled] = useState(false);

  return (
    <>
      <div className={cn("flex mb-4 justify-center", className)}>
        <Button text="The old way"
          bgColor={isToggled ? "bg-transparent" : "bg-black"}
          className="rounded-r-none" noScale={true}
          onClick={
            () => {
              setIsToggled(false);
              setIsAutoplayed(false);
              setHasAutoplay(false);
            }
          }
        />
        <Button text="with Common Room"
          bgColor={isToggled ? "bg-black" : "bg-transparent"}
          className="rounded-l-none"
          noScale={true}
          onClick={
            () => {
              setIsToggled(true)
              setIsAutoplayed(false);
              setHasAutoplay(false);
            }
          } />
      </div>
      <div className="relative">
        <ImageBuilder
          image={imageOne}
          className="rounded-2xl"
        />
        <ImageBuilder
          image={imageTwo}
          className={cn(
            "rounded-2xl absolute top-0 left-0 transition-opacity duration-300",
            isToggled ? "opacity-100" : "opacity-0"
          )}
        />
      </div>
    </>
  )
}
